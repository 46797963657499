import { generalUtils } from '@shared/common';
import { Button, ButtonProps } from '@shared/design-system/atoms/button';
import { cn } from '@shared/design-system/utils/shadcn';
import Link from 'next/link';
import { forwardRef } from 'react';

interface ActionButtonProps extends ButtonProps {
  action: 'navigate' | 'scroll';
  value?: string;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ action, value, children, className, ...rest }, ref) => {
    const handleClick = () => {
      if (action === 'scroll' && value) {
        generalUtils.scrollToElement(value);
      }
    };

    const ButtonComp = (
      <Button className={cn(className)} size='md' onClick={handleClick} {...rest} ref={ref}>
        {children}
      </Button>
    );

    if (action === 'navigate' && value) {
      return (
        <Link className='flex w-full' href={value}>
          {ButtonComp}
        </Link>
      );
    }
    return ButtonComp;
  },
);

ActionButton.displayName = 'ActionButton';
