import { generalUtils } from '@shared/common';
import { Button } from '@shared/design-system/atoms/button';
import { Check, Percent } from 'lucide-react';

import LogoSVG from '~/assets/icons/logo.svg';
import { ProductGallery } from '~/components/index';
import { useAppRouter } from '~/hooks/useAppRouter';

export const Intro = () => {
  const { query } = useAppRouter();
  const handleClick = () => {
    generalUtils.scrollToElement('product');
  };
  const actionBlock = (
    <>
      <div className='flex gap-2 md:mb-12 md:pb-12 md:border-b border-neutral-300 md:flex-col flex-wrap items-center justify-center md:items-start'>
        <div className='flex items-center gap-3'>
          <Check className='text-orange-0' size={18} />
          <p>Helps with weight loss</p>
        </div>
        <div className='flex items-center gap-3'>
          <Check className='text-orange-0' size={18} />
          <p>Lowers cortisol and stress levels</p>
        </div>
        <div className='flex items-center gap-2'>
          <Check className='text-orange-0' size={18} />
          <p>Relieves mood swings</p>
        </div>
      </div>

      <div className='flex flex-col md:flex-row gap-4 md:items-start'>
        <div className='flex flex-col md:flex-1 items-center'>
          <Button className='w-full' size='md' onClick={handleClick}>
            Get it now!
          </Button>
          {query.coupon ? (
            <>
              <div
                className='mt-2 w-0 h-0
  border-l-[4px] border-l-transparent
  border-b-[6px] border-b-[#17BA13]
  border-r-[4px] border-r-transparent'
              ></div>
              <div className='flex bg-[#17BA13] px-3 py-1 rounded '>
                <p className='text-sm text-white text-center'>
                  Coupon code <span className='font-medium'>{query.coupon}</span> has been applied
                </p>
              </div>
            </>
          ) : null}
        </div>
        <div className='flex flex-1 p-2 border border-neutral-300 rounded-full gap-3'>
          <div className='flex bg-orange-50 w-[40px] h-[40px] rounded-full items-center justify-center'>
            <Percent className='text-white' />
          </div>
          <div className='flex md:block items-center gap-1'>
            <p className='font-secondary text-[17px]/none'>Order now</p>
            <p className='text-[14px]/tight'>
              get it ordered for <span className='font-secondary'>free!</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className='w-full mt-4 md:mt-14 mb-10 md:mb-16 px-[6%]'>
      <div className='flex flex-col md:flex-row md:gap-16 max-w-[88rem]'>
        <div className='md:w-[47%]'>
          <div className='flex justify-center md:justify-start mb-4 md:mb-12'>
            <LogoSVG className='w-[116px] md:w-[282px] h-auto' />
          </div>
          <h1 className='font-primary text-[24px]/none md:text-[40px] md:leading-[1.1] text-center md:text-left mb-4 md:mb-6 font-light'>
            <span className='font-secondary text-[26px]/none md:text-[40px]'>Complete natural formula</span>
            <br />
            Harmonia is here to release your stress and help you to lose weight
          </h1>
          <div className='md:block hidden'>{actionBlock}</div>
        </div>
        <div className='flex-1 w-[114%] mx-[-7%] md:m-0 md:w-[53%] relative'>
          <ProductGallery />
          <p className='md:hidden text-xs text-center mt-2'>Swipe left for more!</p>
        </div>
        <div className='flex md:hidden flex-col-reverse gap-6 mt-6 md:mt-0'>{actionBlock}</div>
      </div>
    </div>
  );
};
